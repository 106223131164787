import { Action, State, StateContext } from '@ngxs/store';
import { ObservationType } from '@core/enums/observation-type.enum';
import { ObservationStateModel } from '@store/mobile/observation/observation-state.model';
import {
  AddCurrentObservation,
  AddObservation,
  PatchObservation,
  RemoveObservation,
  ResetCurrentObservation,
  ResetObservation,
  ScanBlock,
} from '@store/mobile/observation/observation.actions';
import { v4 as uuid } from 'uuid';
import { Injectable } from '@angular/core';

@State<ObservationStateModel>({
  name: 'observation',
  defaults: {
    observations: [],
    currentObservation: {
      id: uuid(),
    },
  },
})
@Injectable()
export class ObservationState {
  @Action(ScanBlock)
  public setScannedCode(ctx: StateContext<ObservationStateModel>, { block }: ScanBlock): void {
    const state = ctx.getState();
    const currentObservation = {
      ...state.currentObservation,
      block,
      observationable_type: ObservationType.block,
      observationable_id: block.value,
    };

    ctx.setState({
      ...state,
      currentObservation,
    });
  }

  @Action(PatchObservation)
  public patch(ctx: StateContext<ObservationStateModel>, { observation }: PatchObservation): void {
    const state = ctx.getState();

    ctx.patchState({
      ...state,
      currentObservation: { ...state.currentObservation, ...observation },
    });
  }

  @Action(AddObservation)
  public add(ctx: StateContext<ObservationStateModel>, { observation }: PatchObservation): void {
    const state = ctx.getState();

    const newObservation = { ...observation, observationable_type: ObservationType.block };

    ctx.patchState({
      ...state,
      observations: [...state.observations, newObservation],
    });
  }

  @Action(ResetObservation)
  public reset(ctx: StateContext<ObservationStateModel>): void {
    const defaultState = {
      observations: [],
      currentObservation: {
        id: uuid(),
        observationable_type: ObservationType.block,
      },
    };

    ctx.setState(defaultState);
  }

  @Action(ResetCurrentObservation)
  public resetCurrent(ctx: StateContext<ObservationStateModel>): void {
    ctx.setState({ ...ctx.getState(), currentObservation: { id: uuid() } });
  }

  @Action(RemoveObservation)
  public remove(ctx: StateContext<ObservationStateModel>, { observationId }: RemoveObservation): void {
    const state = ctx.getState();
    const observations = state?.observations.filter((observation) => {
      return observation.id !== observationId;
    });

    ctx.setState({
      ...state,
      observations,
    });
  }

  @Action(AddCurrentObservation)
  public addCurrent(ctx: StateContext<ObservationStateModel>): void {
    const state = ctx.getState();

    ctx.setState({
      observations: [...state.observations, { id: uuid(), ...state.currentObservation }],
      currentObservation: { id: uuid() },
    });
  }
}
